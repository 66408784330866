<template>
  <v-card class="pa-5 mt-5">
    <v-card-title>
      <span class="headline">Conversion Analysis</span>
    </v-card-title>
    <v-card-text>
      <!-- Toggle between Filtered and Overall Data -->
      <v-btn-toggle v-model="analysisMode" class="mb-4">
        <v-btn value="filtered">Filtered Data</v-btn>
        <v-btn value="overall">Overall Data</v-btn>
      </v-btn-toggle>
      
      <!-- Main Tabs -->
      <v-tabs v-model="activeTab" background-color="transparent">
        <v-tab key="chain">Process Chain Analysis</v-tab>
        <v-tab key="group">Product Group Analysis</v-tab>
        <v-tab key="matrix">Input–Output Matrix</v-tab>
        <v-tab key="detailed">Detailed Chain Analysis</v-tab>
      </v-tabs>
      <v-divider class="mb-4"></v-divider>
      
      <!-- Process Chain Analysis -->
      <div v-if="activeTab === 0">
        <p class="mb-4" style="font-style: italic;">
          This analysis displays each process chain from the aggregated data.
          For each chain, the conversion rate is calculated as (total output ÷ total input) × 100%.
          The process chain column shows the process start time and a clickable process code linking to the worksheet.
        </p>
        <v-data-table
          :headers="chainHeaders"
          :items="processChainAnalysis"
          class="elevation-1"
          dense
          :items-per-page="5"
        >
          <template v-slot:item.chainDisplay="{ item }">
            <div v-for="proc in item.chainDisplay" :key="proc.id">
              {{ proc.time | formatDateTime }} -
              <a target="_blank" :href="`https://prod.${domain}.com/factory/process/${proc.id}`">
                {{ proc.code }}
              </a>
            </div>
          </template>
        </v-data-table>
      </div>
      
      <!-- Product Group Analysis -->
      <div v-else-if="activeTab === 1">
        <p class="mb-4" style="font-style: italic;">
          The Product Group Analysis is separated into two tables:
          <br /><br />
          <strong>Input Groups Analysis:</strong>
          <ul>
            <li><em>Total Input</em>: Sum of raw input for the group.</li>
            <li><em>Input %</em>: Group’s share of overall input.</li>
            <li><em>Attributed Output</em>: Output attributed to this input group (from the Input–Output Matrix).</li>
            <li><em>Output %</em>: Percentage of overall output represented by this attributed output.</li>
            <li><em>Efficiency</em>: (Attributed Output ÷ Total Input) × 100%.</li>
            <li><em>Delta</em>: (Output % – Input %), indicating over- or under-performance.</li>
          </ul>
          <strong>Output Groups Analysis:</strong>
          <ul>
            <li><em>Total Output</em>: Sum of outputs for the group.</li>
            <li><em>Output %</em>: Group’s share of overall output.</li>
          </ul>
        </p>
        <v-card outlined class="mb-4">
          <v-card-title>Input Groups Analysis</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="inputGroupHeaders"
              :items="inputGroupAnalysis"
              class="elevation-1"
              dense
              hide-default-footer
            ></v-data-table>
          </v-card-text>
        </v-card>
        <v-card outlined>
          <v-card-title>Output Groups Analysis</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="outputGroupHeaders"
              :items="outputGroupAnalysis"
              class="elevation-1"
              dense
              hide-default-footer
            ></v-data-table>
          </v-card-text>
        </v-card>
      </div>
      
      <!-- Input–Output Matrix -->
      <div v-else-if="activeTab === 2">
        <p class="mb-4" style="font-style: italic;">
          The Input–Output Matrix shows how outputs are distributed across input groups for each process chain.
          Each cell represents the percentage contribution of an input group to a specific output group.
          The final column "Overall %" compares each input group’s total contribution to the combined output across all chains.
        </p>
        <v-data-table
          :headers="matrixHeaders"
          :items="inputOutputMatrixRows"
          class="elevation-1"
          dense
          hide-default-footer
        ></v-data-table>
      </div>
      
      <!-- Detailed Chain Analysis -->
      <div v-else-if="activeTab === 3">
        <p class="mb-4" style="font-style: italic;">
          This detailed view provides a breakdown for each process chain.
          It shows the process chain display (process start time and clickable code) along with a detailed breakdown of input and output percentages by product group.
        </p>
        <v-data-table
          :key="detailedTableKey"
          :headers="detailedChainHeaders"
          :items="detailedChainAnalysis"
          class="elevation-1"
          dense
          :items-per-page="5"
        >
          <template v-slot:item.chainDisplay="{ item }">
            <div v-for="proc in item.chainDisplay" :key="proc.id">
              {{ proc.time | formatDateTime }} -
              <a target="_blank" :href="`https://prod.${domain}.com/factory/process/${proc.id}`">
                {{ proc.code }}
              </a>
            </div>
          </template>
        </v-data-table>
      </div>
      
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ConversionAnalysis",
  props: {
    aggregatedData: { type: Array, required: true },
    rawData: { type: Array, required: true },
    overallAggregatedData: { type: Array, default: null },
    overallRawData: { type: Array, default: null },
  },
  data() {
    return {
      activeTab: 0,
      analysisMode: "filtered", // "filtered" or "overall"
    };
  },
  computed: {
    // Simplify domain for link building.
    domain() {
      const parts = window.location.hostname.split(".");
      return parts.length >= 2 ? parts[parts.length - 2] : window.location.hostname;
    },
    currentAggregatedData() {
      return this.analysisMode === "overall" && this.overallAggregatedData
        ? this.overallAggregatedData
        : this.aggregatedData;
    },
    currentRawData() {
      return this.analysisMode === "overall" && this.overallRawData
        ? this.overallRawData
        : this.rawData;
    },
    overallInputAll() {
      return this.currentRawData
        .filter(item => item.inventoryType === "Input")
        .reduce((sum, item) => sum + (item.weight || 0), 0);
    },
    overallOutputAll() {
      return this.currentRawData
        .filter(item => item.inventoryType === "Output")
        .reduce((sum, item) => sum + (item.weight || 0), 0);
    },
    /* Process Chain Analysis */
    processChainAnalysis() {
      return this.currentAggregatedData.map(item => {
        let conversionRate = 0;
        if (item.totalInput > 0) {
          conversionRate = (item.totalOutput / item.totalInput) * 100;
        }
        let chainDisplay = [];
        if (item.processCodes && item.processCodes.length > 0) {
          chainDisplay = item.processCodes.map(proc => ({
            time: proc.time,
            code: proc.code,
            id: proc.id,
          }));
        }
        return {
          chain: item.chain,
          chainDisplay,
          totalInput: item.totalInput,
          totalOutput: item.totalOutput,
          conversionRate: conversionRate.toFixed(2) + '%',
        };
      });
    },
    chainHeaders() {
      return [
        { text: "Process Chain", value: "chainDisplay" },
        { text: "Total Input", value: "totalInput" },
        { text: "Total Output", value: "totalOutput" },
        { text: "Conversion Rate", value: "conversionRate" },
      ];
    },
    /* Input–Output Matrix (unchanged) */
    inputOutputMatrix() {
      const chainGroups = {};
      this.currentRawData.forEach(item => {
        const chain = item.processChain || item.processId || "Unknown";
        if (!chainGroups[chain]) {
          chainGroups[chain] = { inputs: {}, outputs: {} };
        }
        if (item.inventoryType === "Input") {
          const group = item.productGroup || "Unknown";
          chainGroups[chain].inputs[group] =
            (chainGroups[chain].inputs[group] || 0) + (item.weight || 0);
        } else if (item.inventoryType === "Output") {
          const group = item.productGroup || "Unknown";
          chainGroups[chain].outputs[group] =
            (chainGroups[chain].outputs[group] || 0) + (item.weight || 0);
        }
      });
      
      const matrix = {};
      const inputTotals = {};
      Object.values(chainGroups).forEach(chain => {
        const totalInputChain = Object.values(chain.inputs).reduce((sum, val) => sum + val, 0);
        if (totalInputChain === 0) return;
        for (const [inputGroup, inputWeight] of Object.entries(chain.inputs)) {
          const fraction = inputWeight / totalInputChain;
          for (const [outputGroup, outputWeight] of Object.entries(chain.outputs)) {
            const contribution = fraction * outputWeight;
            if (!matrix[inputGroup]) {
              matrix[inputGroup] = {};
              inputTotals[inputGroup] = 0;
            }
            matrix[inputGroup][outputGroup] =
              (matrix[inputGroup][outputGroup] || 0) + contribution;
            inputTotals[inputGroup] += contribution;
          }
        }
      });
      
      const outputGroupsSet = new Set();
      for (const inputGroup in matrix) {
        for (const outputGroup in matrix[inputGroup]) {
          outputGroupsSet.add(outputGroup);
        }
      }
      const outputGroups = Array.from(outputGroupsSet).sort();
      
      const overallTotal = Object.values(inputTotals).reduce((sum, val) => sum + val, 0);
      
      const rows = [];
      for (const inputGroup in matrix) {
        const row = { inputGroup };
        outputGroups.forEach(outputGroup => {
          const value = matrix[inputGroup][outputGroup] || 0;
          const total = inputTotals[inputGroup] || 1;
          const percent = (value / total) * 100;
          row[outputGroup] = percent.toFixed(2) + '%';
        });
        row.overallComparison = overallTotal > 0
          ? ((inputTotals[inputGroup] / overallTotal) * 100).toFixed(2) + '%'
          : '0%';
        rows.push(row);
      }
      
      const headers = [
        { text: "Input Group", value: "inputGroup" },
        ...outputGroups.map(group => ({ text: group, value: group })),
        { text: "Overall %", value: "overallComparison" }
      ];
      
      return { headers, rows };
    },
    matrixHeaders() {
      return this.inputOutputMatrix.headers;
    },
    inputOutputMatrixRows() {
      return this.inputOutputMatrix.rows;
    },
    /* Computed property: Attributed output for each input group via the Input–Output Matrix */
    inputTotalsMatrix() {
      const chainGroups = {};
      this.currentRawData.forEach(item => {
        const chain = item.processChain || item.processId || "Unknown";
        if (!chainGroups[chain]) {
          chainGroups[chain] = { inputs: {}, outputs: {} };
        }
        if (item.inventoryType === "Input") {
          const group = item.productGroup || "Unknown";
          chainGroups[chain].inputs[group] =
            (chainGroups[chain].inputs[group] || 0) + (item.weight || 0);
        } else if (item.inventoryType === "Output") {
          const group = item.productGroup || "Unknown";
          chainGroups[chain].outputs[group] =
            (chainGroups[chain].outputs[group] || 0) + (item.weight || 0);
        }
      });
      const inputTotals = {};
      Object.values(chainGroups).forEach(chain => {
        const totalInputChain = Object.values(chain.inputs).reduce((sum, val) => sum + val, 0);
        if (totalInputChain === 0) return;
        for (const [inputGroup, inputWeight] of Object.entries(chain.inputs)) {
          const fraction = inputWeight / totalInputChain;
          for (const [outputGroup, outputWeight] of Object.entries(chain.outputs)) {
            const contribution = fraction * outputWeight;
            inputTotals[inputGroup] = (inputTotals[inputGroup] || 0) + contribution;
          }
        }
      });
      return inputTotals;
    },
    /* Product Group Analysis: Revised */
    inputGroupAnalysis() {
      const inputGroups = {};
      let totalInputAll = 0;
      this.currentRawData
        .filter(item => item.inventoryType === "Input")
        .forEach(item => {
          const group = item.productGroup || "Unknown";
          inputGroups[group] = (inputGroups[group] || 0) + (item.weight || 0);
          totalInputAll += item.weight || 0;
        });
      const inputTotalsMat = this.inputTotalsMatrix;
      return Object.keys(inputGroups).map(group => {
        const totalInput = inputGroups[group];
        const attributedOutput = inputTotalsMat[group] || 0;
        const inputPct = totalInputAll > 0 ? (totalInput / totalInputAll) * 100 : 0;
        const outputPct = this.overallOutputAll > 0 ? (attributedOutput / this.overallOutputAll) * 100 : 0;
        const delta = outputPct - inputPct;
        return {
          productGroup: group,
          totalInput,
          inputPercentage: totalInputAll > 0 ? inputPct.toFixed(2) + '%' : '0%',
          attributedOutput: attributedOutput.toFixed(0),
          outputPercentage: this.overallOutputAll > 0 ? outputPct.toFixed(2) + '%' : '0%',
          efficiency: totalInput > 0 ? (attributedOutput / totalInput * 100).toFixed(2) + '%' : '0%',
          delta: totalInput > 0 ? delta.toFixed(2) + '%' : '0%'
        };
      });
    },
    outputGroupAnalysis() {
      const outputGroups = {};
      let totalOutputAll = 0;
      this.currentRawData
        .filter(item => item.inventoryType === "Output")
        .forEach(item => {
          const group = item.productGroup || "Unknown";
          outputGroups[group] = (outputGroups[group] || 0) + (item.weight || 0);
          totalOutputAll += item.weight || 0;
        });
      return Object.keys(outputGroups).map(group => {
        const totalOutput = outputGroups[group];
        return {
          productGroup: group,
          totalOutput,
          outputPercentage: totalOutputAll > 0 ? ((totalOutput / totalOutputAll) * 100).toFixed(2) + '%' : '0%'
        };
      });
    },
    inputGroupHeaders() {
      return [
        { text: "Product Group", value: "productGroup" },
        { text: "Total Input", value: "totalInput" },
        { text: "Input %", value: "inputPercentage" },
        { text: "Attributed Output", value: "attributedOutput" },
        { text: "Output %", value: "outputPercentage" },
        { text: "Efficiency", value: "efficiency" },
        { text: "Delta", value: "delta" },
      ];
    },
    outputGroupHeaders() {
      return [
        { text: "Product Group", value: "productGroup" },
        { text: "Total Output", value: "totalOutput" },
        { text: "Output %", value: "outputPercentage" },
      ];
    },
    /* Detailed Chain Analysis */
    aggregatedMap() {
      const map = {};
      this.currentAggregatedData.forEach(item => {
        const chainKey = item.chain;
        if (item.processCodes && item.processCodes.length > 0) {
          map[chainKey] = item.processCodes.map(proc => ({
            time: proc.time,
            code: proc.code,
            id: proc.id,
          }));
        } else {
          map[chainKey] = [{
            time: item.processStartTime || "",
            code: chainKey,
            id: chainKey,
          }];
        }
      });
      return map;
    },
    detailedChainAnalysis() {
      const chainGroups = {};
      this.currentRawData.forEach(item => {
        const chain = item.processChain || item.processId || "Unknown";
        if (!chainGroups[chain]) {
          chainGroups[chain] = { inputs: {}, outputs: {} };
        }
        if (item.inventoryType === "Input") {
          const group = item.productGroup || "Unknown";
          chainGroups[chain].inputs[group] =
            (chainGroups[chain].inputs[group] || 0) + (item.weight || 0);
        } else if (item.inventoryType === "Output") {
          const group = item.productGroup || "Unknown";
          chainGroups[chain].outputs[group] =
            (chainGroups[chain].outputs[group] || 0) + (item.weight || 0);
        }
      });
      
      const result = [];
      const aggMap = this.aggregatedMap;
      Object.keys(chainGroups).forEach(chain => {
        const data = chainGroups[chain];
        const inputs = data.inputs;
        const outputs = data.outputs;
        const totalInput = Object.values(inputs).reduce((sum, val) => sum + val, 0);
        const totalOutput = Object.values(outputs).reduce((sum, val) => sum + val, 0);
        const inputBreakdown = totalInput > 0
          ? Object.entries(inputs).map(([group, weight]) => `${group}: ${((weight / totalInput) * 100).toFixed(2)}%`).join(", ")
          : "N/A";
        const outputBreakdown = totalOutput > 0
          ? Object.entries(outputs).map(([group, weight]) => `${group}: ${((weight / totalOutput) * 100).toFixed(2)}%`).join(", ")
          : "N/A";
        const chainDisplay = Array.isArray(aggMap[chain]) ? aggMap[chain] : [];
        result.push({
          chainDisplay,
          totalInput,
          totalOutput,
          inputBreakdown,
          outputBreakdown,
        });
      });
      return result;
    },
    detailedChainHeaders() {
      return [
        { text: "Process Chain", value: "chainDisplay" },
        { text: "Total Input", value: "totalInput" },
        { text: "Total Output", value: "totalOutput" },
        { text: "Input Breakdown", value: "inputBreakdown" },
        { text: "Output Breakdown", value: "outputBreakdown" },
      ];
    },
    detailedTableKey() {
      return `detailed-${this.analysisMode}-${this.currentAggregatedData.length}`;
    },
  },
  methods: {
    isFirstRowOfType(index, items) {
      if (index === 0) return true;
      return items[index].type !== items[index - 1].type;
    },
    getRowSpan(type, items) {
      return items.filter(item => item.type === type).length;
    },
  },
};
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>
